import React, { useEffect, useState, useRef } from 'react';
import * as S from './TrustPilotPluginV1.styles.js';

const TrustPilotPluginV1 = props => {
  const {
    attributes,
    templateId,
    businessUnitId,
    height,
    width,
    theme,
    tags,
    stars,
    locale,
    languages
  } = props;

  const [init, setInit] = useState(false);
  const elmRef = useRef(null);

  useEffect(() => {
    if (!init) {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(elmRef.current);
      } else {
        const script = document.createElement('script');
        script.src =
          'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        script.onload = () => {
          window.Trustpilot.loadFromElement(elmRef.current);
        };
        document.body.appendChild(script);
      }
      setInit(true);
    }
  }, [init, setInit, elmRef]);

  return (
    <S.Container
      ref={elmRef}
      h={height}
      data-locale={locale || 'en-GB'}
      data-template-id={templateId}
      data-businessunit-id={businessUnitId}
      data-style-height={height}
      data-style-width={width}
      data-theme={theme || 'light'}
      data-tags={tags}
      data-stars={stars}
      data-review-languages={languages || 'en'}
      {...attributes}
    />
  );
};

export default TrustPilotPluginV1;
